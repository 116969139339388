<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1 color="white">Help Center</h1>
				<v-divider class="mt-5"></v-divider>
			</v-col>

			<v-col>
				<h3>FAQ</h3>
				<v-expansion-panels>
					<v-expansion-panel v-for="(qa, i) in faq" :key="i">
						<v-expansion-panel-header>
							{{ qa.q }}
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="qa.a"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			faq: [
				{
					q: 'Πώς μαζεύω πόντους;',
					a: 'Βλέποντας  τα streams (πρέπει να κλείσετε το <a href="https://trovo.live/settings/privacy" target="_blank">lurking mode</a>), γράφοντας, κάνοντας spells και elixirs!'
				},
				{
					q: 'Πού μπορώ να δω τους πόντους μου;',
					a: 'Με !points στο chat ή στο <a href="/dashboard">Dashboard</a>'
				},
				{
					q: 'Μπορώ να δώσω τους πόντους ή τα rewards μου σε άλλον;',
					a: 'Όχι, τα points και τα rewards είναι personal για κάθε user.'
				},
				{
					q: 'Θα κάνουν reset οι πόντοι μου;',
					a: 'Ακόμα δεν είμαστε σίγουροι γι\'αυτό, καθώς μελετάμε το πως θα δουλεύει μελλοντικά το σύστημα, αλλά υπάρχει μεγάλη πιθανότητα να γίνεται μηνιαίο reset.'
				}
			]
		}
	},
}
</script>

<style>
.v-expansion-panel {
	background-color: #192335 !important;
}
</style>